import 'assets/styles/app.scss';

const loadedComponents = [];

document.addEventListener('DOMContentLoaded', async () => {

	/**.
	 * Bootstrap the components
	 */
	for (const dataComponent of document.querySelectorAll('[data-component]')) {
		let componentName = dataComponent.dataset.component;

		if (loadedComponents.indexOf(componentName) === - 1) {
			loadedComponents.push(componentName);

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
				);

				console.log(componentName);

				component.default();
			} catch (error) {
				console.error('Loading error: ', error);
			}
		}
	}


	/**
	 * Scroll To Top Function
	 */
	const btn = $('.scroll-to-top');

	$(window).scroll(function () {
		if ($(window).scrollTop() > 300) {
			btn.addClass('scroll-to-top--is-visible');
		} else {
			btn.removeClass('scroll-to-top--is-visible');
		}
	});

	btn.on('click', function (e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: 0,
		}, '300');
	});

	/**
	 * Off Canvas Menu
	 */
	const $btnToggle = $('.btn-burger');
	const $offCanvas = $('.off-canvas');
	const $btnOffcanvasClose = $('.js-offcanvas-close');
	const activeClass = 'active';
	const $bodyOverlay = $('.body-overlay');
	const $body = $('body');

	$btnToggle.on('click', () => {
		$offCanvas.toggleClass(activeClass);
		$body.addClass('is-fixed');
		$body.addClass('menu-is-active');
	});

	$btnOffcanvasClose.on('click', () => {
		$offCanvas.toggleClass(activeClass);
		$body.removeClass('is-fixed');
		$body.removeClass('menu-is-active');
	});

	$bodyOverlay.on('click', () => {
		$offCanvas.removeClass('active');
		$body.removeClass('is-fixed');
		$body.removeClass('menu-is-active');
	})

	function setupMobileMenu() {
		$('.off-canvas .menu-item-has-children').on('click', function (e) {
			jQuery(this).toggleClass('open-sub-menu');
			jQuery(this).find('.sub-menu').toggleClass('show');
		});

		jQuery('.off-canvas .menu-item-has-children > a').on('click', function (e) {
			e.preventDefault();
		});
	}

	const showFilters = () => {
		$('.body').addClass('is-fixed');
		$('.sidebar').addClass('sidebar-is-active');

		$('.sidebar').prepend(`
			<div class="sidebar__header">
				<h1>This is the sidebar header</h1>
			</div>
		`);

	}

	$(document).ready(function () {
		setupMobileMenu();
	});

	$('.btn-filters').on('click', (e) => {
		e.preventDefault();

		showFilters();
	});
});


